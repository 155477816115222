"use client";
import * as F from "@radix-ui/react-form";
import clsx from "clsx";
import type { PropsWithChildren } from "react";

import styles from "./form.module.css";

type FormDataValue = { [k: string]: FormDataEntryValue };

const FormRoot = ({
  children,
  className,
  onSubmit,
}: PropsWithChildren<{
  onSubmit?: (data: FormDataValue) => void;
  initialData?: FormDataValue;
  className?: string;
}>) => {
  return (
    <F.Root
      className={clsx(styles.form, className)}
      onSubmit={(event) => {
        event.preventDefault();
        const data: FormDataValue = {};
        const formData = new FormData(event.currentTarget);
        formData.forEach((value, key) => {
          data[key] = value;
        });
        onSubmit?.(data);
      }}
    >
      {children}
    </F.Root>
  );
};

export default FormRoot;
