import * as Form from "@radix-ui/react-form";
import clsx from "clsx";
import type { HTMLInputTypeAttribute } from "react";
import { useEffect, useState } from "react";

import styles from "../form.module.css";

const FormTextInputField = ({
  id,
  label,
  defaultValue = "",
  type = "text",
  valueMissingMessage,
  typeMismatchMessage,
  required,
}: {
  id: string;
  label: string;
  defaultValue?: string;
  type?: HTMLInputTypeAttribute;
  valueMissingMessage?: string;
  typeMismatchMessage?: string;
  required?: boolean | undefined;
}) => {
  const [value, setValue] = useState<string>(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <Form.Field name={id} className={styles.form__field}>
      <Form.Label
        className={clsx(
          styles.Label,
          value.length > 0 && styles.active,
          required && styles.required
        )}
      >
        {label}
      </Form.Label>
      {valueMissingMessage && (
        <Form.Message className={styles.error} match="valueMissing" data-error>
          {valueMissingMessage}
        </Form.Message>
      )}
      {typeMismatchMessage && (
        <Form.Message className={styles.error} match="typeMismatch" data-error>
          {typeMismatchMessage}
        </Form.Message>
      )}
      <Form.Control asChild>
        <input
          className={clsx(styles.input, value.length > 0 && styles.active)}
          type={type}
          onChange={(event) => setValue(event.target.value)}
          required={required}
          value={value}
          // defaultValue={defaultValue}
        />
      </Form.Control>
    </Form.Field>
  );
};

export default FormTextInputField;
