import * as Form from "@radix-ui/react-form";
import clsx from "clsx";
import type { PropsWithChildren } from "react";

import styles from "../form.module.css";

const FormSubmit = ({
  children = "Lagre",
  className,
  asChild,
}: PropsWithChildren<{
  className?: string;
  asChild?: boolean;
}>) => {
  return (
    <Form.Submit
      className={clsx(styles.submit__button, className)}
      asChild={asChild}
    >
      {children}
    </Form.Submit>
  );
};

export default FormSubmit;
