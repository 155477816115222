import * as Form from "@radix-ui/react-form";
import clsx from "clsx";

import styles from "./checkbox.module.css";

const FormCheckbox = ({
  id,
  label,
  inputClassName,
  required = false,
  defaultChecked = false,
}: {
  id: string;
  label: string;
  inputClassName?: string;
  required?: boolean | undefined;
  defaultChecked?: boolean;
}) => {
  return (
    <Form.Field name={id} className={styles.root}>
      <Form.Control asChild>
        <input
          className={clsx(styles.input, inputClassName)}
          type="checkbox"
          required={required}
          defaultChecked={defaultChecked}
        />
      </Form.Control>
      <Form.Label className={"label"}>{label}</Form.Label>
    </Form.Field>
  );
};

export default FormCheckbox;
