"use client";
import Link from "next/link";

import { Button } from "@/components/atoms";
import Form from "@/components/molecules/Form";
import { useLogin } from "@/lib/hooks/useSelection";

import styles from "../guest.module.css";

const LogInForm = () => {
  const { login: loginFunction } = useLogin();
  const loginErrored = loginFunction.error;

  return (
    <Form.Root
      onSubmit={(data) => {
        loginFunction.mutate({
          email: data.email as string,
          password: data.password as string,
        });
      }}
    >
      <div className={styles.inputs}>
        <Form.TextInputField
          id="email"
          label="Email"
          valueMissingMessage="Please add your email"
          typeMismatchMessage="Please use a valid email"
          required
        />
        <Form.TextInputField
          id="password"
          label="Password"
          type="password"
          valueMissingMessage="Please add your password"
          typeMismatchMessage="Invalid password type"
          required
        />
      </div>

      <Form.Submit asChild>
        <Button
          color="black"
          grow
          textColor="white"
          className="uppercase"
          style={{ width: "100%", paddingBlock: "1rem" }}
          shadow
        >
          Sign in
        </Button>
      </Form.Submit>
      {loginErrored && (
        <p className={styles.error}>Couldn&apos;t find username or password</p>
      )}

      <div className={styles.options}>
        <Form.Checkbox id="remember" label="Remember me" />
        <Link className="underline-hover" href="/glemt-passord">
          Forgotten password?
        </Link>
      </div>
    </Form.Root>
  );
};

const LogIn = () => {
  return (
    <div className={styles.page} data-layout="page">
      <section className={styles.section}>
        <h1>Log in to your account</h1>
        <LogInForm />
        <div className={styles.divider} aria-hidden />
        <h2>Not registred? </h2>

        <Link href={"/registrer"}>
          <Button
            color="transparent"
            outlined
            grow
            className="uppercase"
            style={{ width: "100%", paddingBlock: "1rem" }}
          >
            Create an account
          </Button>
        </Link>
      </section>
    </div>
  );
};

export default LogIn;
