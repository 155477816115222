import FormRoot from "./Form";
import FormCheckbox from "./FormCheckbox";
import FormTextInputField from "./FormInputField";
import FormSubmit from "./FormSubmit";

const Form = {
  Root: FormRoot,
  TextInputField: FormTextInputField,
  Checkbox: FormCheckbox,
  Submit: FormSubmit,
};

export default Form;
